<template>
	<div>
		<h3 class="quick-info-header" v-if="this.$slots.heading() != ''">
			<slot name="heading"></slot>
		</h3>
		<div class="quick-info-content" v-if="this.$slots.content() != ''">
			<slot name="content"></slot>
		</div>
	</div>
</template>





<script>
export default {

	props: {
		// heading: {
		// 	type: String,
		// 	required: false,
		// 	default: ""
		// },
		// content: {
		// 	type: String,
		// 	required: false,
		// 	default: ""
		// }
	},



	data()
	{
		return {

		};
	},



	created()
	{

	},


	updated()
	{

	},


	mounted()
	{


	},




	methods: {

	}
}
</script>







<style scoped lang="scss">
	.quick-info-header
	{
		font-size: 1.25em;
		font-weight: bold;
	}


	.quick-info-content
	{
		font-size: 1em;
		font-weight: normal;
	}
</style>