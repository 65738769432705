const routes = [

	//NOTE: This module was never finished and is obsolete.
	// ---- Quotes module

	// Quoting dashboard.
	{
		path: '/module/quotes',
		name: 'quotes-root',
		component: () => import('./module.quotes/quotes-dashboard.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes",
		},
	},



	// Manage quotes page -- non dashboard version of quoting.
	{
		path: '/module/quotes/manage',
		name: 'quotes-manage',
		component: () => import('./module.quotes/quotes-manage.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes > Manage",
		},
	},



	// New quote Select client / client info page
	{
		path: '/module/quotes/new/select-client',
		name: 'quotes-select-client',
		component: () => import('./module.quotes/quotes-select-client.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes > New > Select Client",
		},
	},


	// New Quote -> new client / client info page
	{
		path: '/module/quotes/new/new-client',
		name: 'quotes-new-client',
		component: () => import('./module.quotes/quotes-new-client.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes > New > New Client",
		},
	},


	{
		path: '/module/quotes/new/confirm-client-details/:id',
		name: 'quotes-confirm-client-details',
		component: () => import('./module.quotes/quotes-confirm-client-details.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Confirm Client details for New Quote",
		},
	},


	// Existing quote / edit quote / review quote page.
	{
		path: '/module/quotes/id/:id/edit',
		name: 'quotes-edit',
		component: () => import('./module.quotes/quotes-edit-quote.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes > Edit Quote",
		},
	},



	// Existing quote / quote overview page .
	{
		path: '/module/quotes/id/:id/overview',
		name: 'quotes-overview',
		component: () => import('./module.quotes/quotes-quote-overview.vue'),
		meta: {
			layout: "primary",
			section: "quotes",
			pageTitle: "Quotes > Quote Overview",
		},
	},






	{ 
		path: '/manage/quotes', 
		name: "manage-quotes", 
		component: () => import("./module.quotes/quotes.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Quotes", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/quote/:id/edit', 
		name: "manage-quote-edit", 
		component: () => import("./module.quotes/quotes-edit-quote.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Quote > Edit", kbFile: "manage", kbTitle: "Manage" }
	},

	{
		path: '/manage/quote/:id/overview', 
		name: "manage-quote-overview", 
		component: () => import("./module.quotes/quotes-quote-overview.vue"),
		meta: { layout: "primary", layoutType: "manage", pageTitle: "Manage > Quote > Overview", kbFile: "manage", kbTitle: "Manage" }
	},

]


export default routes;