const routes = [


	// Shared pages and functionality across modules (e.g. editing/managing shared entities like clients and addresses).
	//---- "Manage" pages
	{ 
		path: '/manage',
		name: "manage-home", 
		component: () => import("./shared/index.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage", kbFile: "manage", kbTitle: "Manage" }
	},

	{ 
		path: '/manage/jobs', 
		name: "manage-jobs", 
		component: () => import("./shared/jobs.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Jobs", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/job/:jobId/edit', 
		name: "manage-jobs-edit", 
		component: () => import("./shared/job-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Job > Edit", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/jobs/new', 
		name: "manage-jobs-new", 
		component: () => import("./shared/job-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Jobs > New", kbFile: "manage", kbTitle: "Manage" }
	},


	{ 
		path: '/manage/addresses', 
		name: "manage-addresses", 
		component: () => import("./shared/addresses.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Addresses", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/address/:addressId/edit', 
		name: "manage-address-edit", 
		component: () => import("./shared/address-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Address > Edit", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/addresses/new', 
		name: "manage-addresses-new", 
		component: () => import("./shared/address-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Addresses > New", kbFile: "manage", kbTitle: "Manage" }
	},


	{ 
		path: '/manage/organisations', 
		name: "manage-organisations", 
		component: () => import("./shared/organisations.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Organisations", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/organisation/:organisationId/edit', 
		name: "manage-organisation-edit", 
		component: () => import("./shared/organisation-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Organisation > Edit", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/organisations/new', 
		name: "manage-organisations-new", 
		component: () => import("./shared/organisation-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Organisations > New", kbFile: "manage", kbTitle: "Manage" }
	},


	{ 
		path: '/manage/people', 
		name: "manage-people", 
		component: () => import("./shared/people.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > People", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/people/:personId/edit', 
		name: "manage-person-edit", 
		component: () => import("./shared/person-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Person > Edit Person", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/people/new', 
		name: "manage-people-new", 
		component: () => import("./shared/person-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > People > New Person", kbFile: "manage", kbTitle: "Manage" }
	},



	


	{ 
		path: '/manage/:accountId/clients', 
		name: "manage-clients", 
		component: () => import("./shared/clients.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Clients", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/client/:clientId/edit', 
		name: "manage-clients-edit", 
		component: () => import("./shared/client-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Client", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/clients/new', 
		name: "manage-clients-new", 
		component: () => import("./shared/client-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > New Client", kbFile: "manage", kbTitle: "Manage" }
	},

	{ 
		path: '/manage/:accountId/buckets',
		name: "manage-buckets", 
		component: () => import("./shared/buckets.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Buckets", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/bucket/:bucketId/edit', 
		name: "manage-bucket-edit", 
		component: () => import("./shared/bucket-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Bucket", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/buckets/new', 
		name: "manage-bucket-new", 
		component: () => import("./shared/bucket-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > New Bucket", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/client/:clientId/buckets/new', 
		name: "manage-bucket-new-for-client", 
		component: () => import("./shared/bucket-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > New Bucket", kbFile: "manage", kbTitle: "Manage" }
	},


	{ 
		path: '/manage/:accountId/clients-buckets-tasks',
		name: "manage-clients-buckets-tasks", 
		component: () => import("./shared/clients-buckets-tasks.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Clients", kbFile: "manage", kbTitle: "Manage" }
	},




	{ 
		path: '/manage/:accountId/bucket/:bucketId/tasks', 
		name: "manage-bucket-tasks", 
		component: () => import("./shared/bucket-tasks.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Bucket > Tasks", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/bucket/:bucketId/tasks/:taskId/edit', 
		name: "manage-bucket-task-edit", 
		component: () => import("./shared/bucket-task-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Bucket > Edit Task", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/bucket/:bucketId/tasks/new', 
		name: "manage-bucket-task-new", 
		component: () => import("./shared/bucket-task-edit.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Bucket > New Task", kbFile: "manage", kbTitle: "Manage" }
	},



	{ 
		path: '/manage/:accountId/bucket/keywords', 
		name: "manage-keywords-all", 
		component: () => import("./shared/bucket-keywords.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Keywords", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/bucket/:bucketId/keywords', 
		name: "manage-bucket-keywords", 
		component: () => import("./shared/bucket-keywords.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Bucket > Keywords", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/pending-keywords', 
		name: "manage-pending-keywords", 
		component: () => import("./shared/pending-keywords.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Pending Keywords", kbFile: "manage", kbTitle: "Manage" }
	},
	{ 
		path: '/manage/:accountId/bucket/:bucketId/rates', 
		name: "manage-bucket-rates", 
		component: () => import("./shared/rates.vue"),
		meta: { layout: "primary", layoutType: "manage", layoutContainer: "none", pageTitle: "Manage > Buckets", kbFile: "manage", kbTitle: "Manage" }
	},

]


export default routes;