const routes = [

	
	//---- "Account Admin" pages
	{ 
		path: '/account-admin',
		name: "account-admin-root", 
		component: () => import("./account-admin/index.vue"),
		// meta: { layout: "account-admin", pageTitle: "Account Admin", kbFile: "account-admin", kbTitle: "Account Admin" }
		meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin", kbFile: "account-admin", kbTitle: "Account Admin" }
	},

	// Create a new account as a Lotus person. Based on the original `path: '/system-admin/accounts/new',`
	{ 
		path: '/system-admin/accounts/new', 	
		name: "system-admin-accounts-new",
		component: () => import("./system-admin/account.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account" }
	},

	{ 
		path: '/account-admin/:accountId',
		name: "account-admin-home", 
		component: () => import("./account-admin/index.vue"),
		//meta: { layout: "account-admin", pageTitle: "Account Admin", kbFile: "account-admin", kbTitle: "Account Admin" }
		meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin > Account details", kbFile: "account-admin", kbTitle: "Account Admin" }
	},

	{ 
		path: '/account-admin/:accountId/user/:id', 
		name: "account-admin-user-edit", 
		component: () => import("./account-admin/user.vue"),
		meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin > Update Person", kbFile: "account-admin", kbTitle: "Account Admin" }
	},
	{ 
		path: '/account-admin/:accountId/user/:id/access', 
		name: "account-admin-user-access", 
		component: () => import("./account-admin/user-access.vue"),
		meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin > Manage Person Access", kbFile: "account-admin", kbTitle: "Account Admin" }
	},
	{ 
		path: '/account-admin/:accountId/users/new',  
		name: "account-admin-user-new", 
		component: () => import("./account-admin/user.vue"),
		meta: { layout: "primary", layoutType: "account-admin", pageTitle: "Account Admin > New Person", kbFile: "account-admin", kbTitle: "Account Admin" }
	},

]


export default routes;