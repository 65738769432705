const routes = [

	
	//---- "Mobile App" web view pages 
	//     (for direct access from the mobile app, either in a web view or in a native browser)
	//NOTE: These are from the original version of the Lotus app, for the quoting module.
	{ 
		path: '/mobile-app/manage-jobs',
		name: "mobile-app-manage-jobs", 
		component: () => import("./mobile-app/manage-jobs.vue"),
		meta: { layout: "mobileappembeddedview", layoutType: "default", layoutContainer: "card", pageTitle: "Manage Jobs" }
	},
	{ 
		path: '/mobile-app/new-job',
		name: "mobile-app-new-job", 
		component: () => import("./mobile-app/edit-job.vue"),
		meta: { layout: "mobileappembeddedview", layoutType: "default", layoutContainer: "card", pageTitle: "New Job" }
	},
	{ 
		path: '/mobile-app/edit-job/:jobId',
		name: "mobile-app-edit-job", 
		component: () => import("./mobile-app/edit-job.vue"),
		meta: { layout: "mobileappembeddedview", layoutType: "default", layoutContainer: "card", pageTitle: "Modify Job" }
	},



	//---- "Mobile App" V2 - a web native "app" for running in the browser.
	{ 
		path: '/app/home',
		name: "mobile-app-home", 
		component: () => import("./mobile-app/home.vue"),
		meta: { layout: "mobileapp", layoutType: "default", layoutContainer: "card", pageTitle: "Home" }
	},





	//---- "Mobile App" V2: for "Simpatico"
	//TODO: Under development
	{ 
		path: '/app/module/simpatico/shifts',
		name: "mobile-app-simpatico-shifts", 
		component: () => import("./mobile-app/module/simpatico/shifts.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Simpatico > Shifts" }
	},
	{ 
		path: '/app/module/simpatico/shift/client/:clientId',
		name: "mobile-app-simpatico-shift", 
		component: () => import("./mobile-app/module/simpatico/shift.vue"),
		meta: { layout: "no", layoutType: "default", layoutContainer: "card", pageTitle: "Simpatico > Shift" }
		//meta: { layout: "mobileapp", layoutType: "default", layoutContainer: "card", pageTitle: "Simpatico > Shift" }
	},



]


export default routes;