<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="standard-dialog">
        <el-dialog
            :title="title"
			:close-on-click-modal="closeOnClickMask"
            v-model="dialogVisible"
            :before-close="handleClose">
			
			<template #header>
				<slot name="header"></slot>
			</template>
			
            <div class="icon" v-if="$slots.content">
                <slot name="content"></slot>
            </div>

			<template #footer v-if="!hideFooter">
				<div class="dialog-footer row mx-0">
					<div class="icon" v-if="$slots.buttons">
						<slot name="buttons"></slot>
					</div>
					<div class="text-left col-6 px-0">
						<el-button type="primary" @click="okHandle()" v-if="canOk">{{okTitle}}</el-button>

						<span style="display: inline-block; width: 10px;"></span>
						
						<c-button-standard type="danger" id="delete" @click="deleteHandle()" v-if="canDelete">
							<template v-slot:icon></template>
							<template v-slot:text>{{deleteTitle}}</template>
						</c-button-standard>
					</div>

					<el-button class="d-inline-block ml-auto" @click="cancelHandle()" v-if="canCancel">{{cancelTitle}}</el-button>
				</div>
			</template>
        </el-dialog>
    </div>
</template>




<script>
export default {

	emits: ["close"],

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: [String, Array],
			default: ""
		},


		/**
		 * True makes the modal visible. False makes it hidden.
		 */
		show: {
			type: Boolean,
			required: true,
			default: false
		},


		/**
		 * The title displayed in the top bar.
		 */
		title: {
			type: String,
			required: false,
            default: ""
		},




		/**
		 * True to completely remove the footer (buttoms) area
		 */
		hideFooter: {
			type: Boolean,
			required: false,
			default: false
		},


		//TODO: Nope
		// /**
		//  * Controls if the modal will auto-close itself on select of a button 
		//  * ("OK", "Cancel" or "Delete"). Default is "true".
		//  * Set to "false" to allow the caller to control the closing.
		//  */
		// autoClose: {
		// 	type: Boolean,
		// 	required: false,
		// 	default: true
		// },

		/**
		 * Controls if the modal will auto-close itself on select of the "OK" button 
		 * Set to "false" to allow the caller to control the closing.
		 */
		okCanClose: {
			type: Boolean,
			required: false,
			default: true
		},







		/**
		 * Is the OK button available to use?
		 */
		canOk: {
			type: Boolean,
			required: false,
			default: true
		},


		/**
		 * Text to display on the "OK" button
		 */
		okTitle: {
			type: String,
			required: false,
            default: "OK"
		},

		/**
		 * Callback function when the "OK" button is activated.
		 */
		onOk: {
			type: Function,
			required: false,
            default: null
		},






		/**
		 * Is the "Delete" button available to use?
		 */
		canDelete: {
			type: Boolean,
			required: false,
			default: false
		},


		/**
		 * Text to display on the "Delete" button
		 */
		deleteTitle: {
			type: String,
			required: false,
            default: "Delete"
		},


		/**
		 * Callback function when the "Delete" button is activated.
		 */
		onDelete: {
			type: Function,
			required: false,
            default: null
		},






		/**
		 * Is the "Cancel"" button available to use?
		 */
		canCancel: {
			type: Boolean,
			required: false,
			default: true
		},


		/**
		 * Text to display on the "Cancel" button
		 */
		cancelTitle: {
			type: String,
			required: false,
            default: "Cancel"
		},


		/**
		 * Callback function when the "Cancel" button is activated.
		 */
		onCancel: {
			type: Function,
			required: false,
            default: null
		},


		cancelAutoClose: {
			type: Boolean,
			required: false,
			default: true,
		},


		/**
		 * Set to true so a "close" is action when the user clicks on the overlay mask.
		 */
		closeOnClickMask: {
			type: Boolean,
			required: false,
			default: false
		}



	},





	data()
	{
		return {
			// states: {
			// 	expanded: "expanded",
			// 	collapsed: "collapsed"
			// },

            dialogVisible: false,

			//state: ""	// Values: "expanded", "collapsed"
		};
	},





	watch: {
		show: function(to, from)
		{
            //console.log("c-modal.watch(show): from=", from, "to=", to); 

			this.dialogVisible = this.show;
		},

		// updateValue: function(to, from)
		// {
		// 	console.log("c-input-date.watch(updateValue): from=", from, "to=", to); 

		// 	this.value = this.updateValue;
		// }
	},






	mounted()
	{
		this.dialogVisible = this.state;


		// Determine if Font Awesome icon should be used.
		// this.showFontAwesomeIcon = false;
		// if (this.fontAwesomeIconClasses != "") this.showFontAwesomeIcon = true;
	},





	computed: {

	},





	methods: {
		onClick()
		{
			// Toggle the state.
			// if (this.state == this.states.collapsed) 
			// {
			// 	this.state = this.states.expanded;
			// 	return;
			// }


			// if (this.state == this.states.expanded)
			// {
			// 	this.state = this.states.collapsed;
			// 	return;
			// }
		},


        handleClose(done)
        {
			//TODO: Just an example
            // this.$confirm('Are you sure to close this dialog?')
            // .then(_ => {
            //     done();
            // })
            // .catch(_ => {});

			this.close();
        },



		okHandle()
		{
			//console.log("okHandle()");
			if (this.onOk) this.onOk();

            if (this.okCanClose)
			{
				// Modal can close itself when clicking OK
				this.close();
			}
			else
			{
				// The caller/host of the modal controls closing it.
			}

			
		},
		


		deleteHandle()
		{
			if (this.onDelete) this.onDelete();

			this.close();
		},
		


		cancelHandle()
		{
			//console.log("modal.cancelHandle(): this.onCancel=", this.onCancel);
			if (this.onCancel) this.onCancel();

			if(this.cancelAutoClose) this.close();
		},


        close()
        {
			//console.log("close(): this.autoClose=", this.autoClose);

			//TODO: Nopoe
            // if (this.autoClose)
			// {
			// 	// Modal can close itself.
			// 	this.dialogVisible = false;

			// 	this.$emit('close', {});	// On emit after actually closing it.
			// }
			// else
			// {
			// 	// The caller/host of the modal controls closing it.
			// }

			this.dialogVisible = false;
			this.$emit('close', {});	// On emit after actually closing it.
        }
	}
}
</script>




<style lang="scss" scoped>

</style>

<style>
	.standard-dialog .el-dialog
	{
		width: 90%;
		max-width: 800px;
		margin-top: 20px;
	}

	.standard-dialog .el-dialog__body {
		word-break: normal;
	}

	.standard-dialog .el-dialog__title
	{
		font-weight: bold;
	}

	
</style>