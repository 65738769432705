<template>
	<div class="current-status-container">

		<div class="row">
			<div class="col-6">
				<h3>Welcome, Jason</h3>
			</div>
			<div class="col-6 text-right">
				<h3>Tuesday, 22 December</h3>
			</div>
		</div>


		<div v-if="$parent.currentStatus.status=='not-checked-in'" class="mt-4">
			<h2 class="current-status-section-header">You are not checked in</h2>

			<div class="mt-4">
				<h3 class="current-status-section-header">Next job at</h3>

				<p class="current-status-value-display">{{$parent.getCurrentStatus.address}}</p>
			</div>

			<div class="mt-4">
				<h3 class="current-status-section-header">Starting in</h3>

				<p class="current-status-value-display">{{$parent.getCurrentStatus.startingIn}}</p>
			</div>

			<div class="mt-4">
				<h3 class="current-status-section-header">For</h3>

				<p class="current-status-value-display">{{$parent.getCurrentStatus.clientName}}</p>
			</div>

			<div class="mt-4">
				<h3 class="current-status-section-header">On bucket</h3>

				<p class="current-status-value-display">{{$parent.getCurrentStatus.bucketPath}}</p>
			</div>

			<div class="mt-4 pb-4">
				<c-button-standard type="link" css-style="width: 100%;" id="manual-check-in" @click="manualCheckInClick()">
					<template v-slot:text>Manual check in</template>
				</c-button-standard>
			</div>
		</div>


		<div v-if="$parent.currentStatus.status=='checked-in'" class="mt-4">
			<div class="mt-4 pb-4">
				<c-button-standard type="link" css-style="width: auto;" id="manual-check-in" @click="manualCheckInClick()">
					<template v-slot:text>Add Commentary</template>
				</c-button-standard>
			</div>

			<h2 class="current-status-section-header">Checked in at</h2>

			<div class="mt-4">
				<p class="current-status-value-display">{{$parent.getCurrentStatus.address}}</p>
			</div>

			<div class="mt-4">
				<h3 class="current-status-section-header">For</h3>

				<p class="current-status-value-display">{{$parent.getCurrentStatus.clientName}}</p>
			</div>
			
			<div class="mt-4">
				<h3 class="current-status-section-header">To</h3>

				<p class="current-status-value-display">{{$parent.getCurrentStatus.bucketPath}}</p>
			</div>

			<div class="mt-4 total-time-on-bucket-today-container">
				<h3 class="current-status-section-header">Total time on this bucket today</h3>

				<p class="current-status-value-display">{{$parent.getCurrentStatus.totalTimeOnBucketToday}}</p>
			</div>

			<div class="mt-4 pb-4">
				<c-button-standard type="link" css-style="width: 100%;" id="manual-check-out" @click="manualCheckOutClick()">
					<template v-slot:text>Manual check out</template>
				</c-button-standard>
			</div>


			<div class="mt-5">
				<h3 class="current-status-section-header">You are currently working on</h3>

				<p class="current-status-value-display">{{$parent.getCurrentStatus.tasks[3].name}}</p>

				

				<div class="mt-4 total-time-on-bucket-today-container">
					<h3 class="current-status-section-header">Total time on this task today</h3>

					<p class="current-status-value-display">{{$parent.getCurrentStatus.totalTimeOnBucketToday}}</p>
				</div>

				<div class="row mt-2">
					<div class="col-6">
						<c-button-standard type="link" css-style="width: 100%;" id="change-task" @click="changeTaskClick()">
							<template v-slot:text>Change task</template>
						</c-button-standard>
					</div>
					<div class="col-6">
						<c-button-standard type="link" css-style="width: 100%; background-color: #111; color: #fff; " id="complete-task" @click="changeTaskClick()">
							<template v-slot:text>Complete task</template>
						</c-button-standard>
					</div>
				</div>
			</div>

		</div>      










		<el-dialog
			v-model="taskDialogVisible"
			class="task-modal"
			:show-close="false"
			style="max-height: 644px; max-width: 360px;"
			:before-close="handleTaskDialogClose"
		>
			<h2 class="current-status-section-header">Tasks</h2>

			<div class="mt-4" v-for="task in $parent.getCurrentStatus.tasks" :key="'tasks-task' + task.id">
				<div :class="{'row': true, 'selected-task': $parent.currentStatus.selectedTaskID == task.id}" @click="selectTaskClick(task.id)" style="cursor: pointer">
					<!-- <div class="col-1">
						<br>
						<span v-show="$parent.currentStatus.selectedTaskID != task.id"><i class="fas fa-hand-pointer"></i></span>
						<span v-show="$parent.currentStatus.selectedTaskID == task.id"><i class="fas fa-check"></i></span>
					</div> -->
					<div class="col-8 text-left">
						<span style="font-weight: bold;">
							{{task.name}}
						</span>

						<br>
						<!-- Allocated: {{task.duration}}<br> -->
						Time recorded: {{task.timeRecorded}}
					</div>
					<div class="col-4 text-right">
						{{task.statusFull}}
					</div>
				</div>
			</div>



			<template #footer>
				<div class="row mt-4">
					<div class="col-6">
						<c-button-standard type="link" css-style="width: 100%; background-color: #111; color: #fff;" id="change-task" @click="changeTaskClick()">
							<template v-slot:text>Select task</template>
						</c-button-standard>
					</div>
					<div class="col-6">
						<c-button-standard type="link" css-style="width: 100%; background-color: #fff; color: #111;" id="complete-task" @click="cancelChangeTaskClick()">
							<template v-slot:text>Cancel</template>
						</c-button-standard>
					</div>
				</div>
			</template>
		</el-dialog>









		
		<el-dialog
			v-model="checkInModalVisible"
			class="task-modal"
			:show-close="false"
			style="max-height: 644px; max-width: 360px;"
			:before-close="handleTaskDialogClose"
		>
			<h2 class="current-status-section-header">Manual Check In</h2>
			<p>Why you are checking in manually?</p>
			<textarea style="width: 100%; height: 250px; border: solid 1px #aaa">Some text here...</textarea>



			<template #footer>
				<div class="row mt-4">
					<div class="col-6">
						<c-button-standard type="link" css-style="width: 100%; background-color: #111; color: #fff;" id="change-task" @click="saveCheckInClick()">
							<template v-slot:text>Check In</template>
						</c-button-standard>
					</div>
					<div class="col-6">
						<c-button-standard type="link" css-style="width: 100%; background-color: #fff; color: #111;" id="complete-task" @click="cancelCheckInClick()">
							<template v-slot:text>Cancel</template>
						</c-button-standard>
					</div>
				</div>
			</template>
		</el-dialog>










		<div v-if="$parent.currentStatus.status=='change-task'" class="mt-4">

		</div>

	</div>
</template>

<script>
export default {
	data() {
		return {
			//$parent.currentStatus.status=='change-task'
			taskDialogVisible: false,
			checkInModalVisible: false,
		}
	},



	methods: {


		handleTaskDialogClose()
		{
			this.taskDialogVisible = false;
			this.checkInModalVisible = false;
		},

        //-- Current Status
		manualCheckInClick()
		{
			this.$parent.currentStatus.status = "checked-in";
			this.checkInModalVisible = true;
		},


		manualCheckOutClick()
		{
			this.$parent.currentStatus.status = "not-checked-in";
			this.checkInModalVisible = true;
		},



		changeTaskClick()
		{
			this.$parent.currentStatus.status = "change-task";
			this.taskDialogVisible = true;
		},


		cancelChangeTaskClick()
		{
			this.$parent.currentStatus.status = "checked-in";
			this.taskDialogVisible = false;
		},



		saveChecKInClick()
		{
			this.$parent.currentStatus.status = "change-task";
			this.checkInModalVisible = true;
		},


		cancelCheckInClick()
		{
			this.$parent.currentStatus.status = "checked-in";
			this.checkInModalVisible = false;
		},



		selectTaskClick(taskId)
		{
			this.$parent.currentStatus.selectedTaskID = taskId;
		},
	}
}
</script>

<style lang="scss" scoped>
@import './mobile-app.scss';


	.mobile-container
	{
		display: none !important;
	}

	// :deep(.task-modal) :deep(.el-dialog__header)
</style>

<style lang="scss">
	.mobile-container .el-dialog.task-modal .el-dialog__header
	{
		display: none;
	}
</style>