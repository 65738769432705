<template>
	<div>
		{{ addressDisplay }}
	</div>
	<div v-if="1==2" style="border: 1px solid blue">
		trackedAddress: {{ trackedAddress }}
	</div>

	<!-- <c-button-standard id="add-new-address" size="small" @click="onAddNewAddress()" type="primary" v-if="!addressHasValue(address)">
		<template v-slot:text>
			<span class="d-none d-sm-block d-md-block"><i class="fas fa-plus"></i> Add</span>
			<span class="d-block d-sm-none d-md-none"><i class="fas fa-plus"></i> Add New Address</span>
		</template>
	</c-button-standard> -->

	<!-- v-if="addressHasValue(trackedAddress)" -->
	<div v-if="trackedAddress">
		<c-button-standard id="add-new-address" size="small" @click="onShowAddressInput(trackedAddress.ID)" type="primary" v-if="!readOnly">
			<template v-slot:text>
				<span><i class="fas fa-edit"></i> {{ getSetButtonLabel() }}</span>
			</template>
		</c-button-standard>

		<c-modal id="EditAddressModal"
			:show="showAddressEditModal"
			:title="editAddressModalTitle"
			@close="showAddressEditModal = false"
			:hideFooter="true"

			:okCanClose="false"
			:canOk="false"
			:canDelete="false"
			:canCancel="false"
		>
			<template v-slot:content>
				<address-edit-form 
					:address-id="selectedAddressId" 
					:showCountryOther="showCountryOther"
					:showCoordinates="showCoordinates"
					:showActivationRadius="showActivationRadius"
					@saved="onAddressModalSaved"
					@cancelled="onAddressModalCancelled">
				</address-edit-form>
			</template>
			<template v-slot:buttons>
			</template>
		</c-modal>
	</div>
</template>





<script>
import {ref} from 'vue';
import addressEditForm from '/shared/address-edit-form.vue';
import addressHelpers from '/helpers/addressHelpers.js';

export default {
	emits: ["set"],


	components: 
	{ 
		'address-edit-form': addressEditForm,
	},


	setup() {
		const showAddressEditModal = ref(false);
		const selectedAddressId = ref("");
		const locationAddressID = ref("");
		const editAddressModalTitle = ref("Set Address");

		// The address to operate on after initially being set from the prop value.
		const trackedAddress = ref({
			ID: ""
		});

		const addressDisplay = ref("");


		return {
			trackedAddress,
			addressDisplay,

			showAddressEditModal,
			selectedAddressId,
			locationAddressID,
			editAddressModalTitle
		}
   },






   props: {

		// :id parameter passed into the page
		id: {
			type : [Number,String],
			required: true
		},


		address: {
			type: [Object, null],
			required: false,
			default: null
		},

		/**
		 * Set to true to disable the ability to change the address,
		 * making it read only.
		 */
		readOnly: {
			type: Boolean,
			required: false,
			default: false
		},



		// 21 April 2024 [@jason]: Prior to adding these to this and the address-edit-form components, the
		// fields were visible by default. But they're specialised fields so I'm hiding them by default and we 
		// can add them back in as needed.
        showCountryOther: {
			type: Boolean,
			required: false,
            default: false
		},
        showCoordinates: {
			type: Boolean,
			required: false,
            default: false
		},
		showActivationRadius: {
			type: Boolean,
			required: false,
            default: false
		},
	},



	watch: {
		'address': function(to, from)
		{
			// console.log("set-and-show-address.watch(address): from=", from, "to=", to); 

			this.init();
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		init()
		{
			console.log("set-and-show-address:init(): this.address=", this.address);

			if (this.address)
			{
				this.trackedAddress = this.address;
				this.setAddressDisplay(this.trackedAddress);
			}
		},



		setAddressDisplay(address)
		{
			// console.log("set-and-show-address(): address=", address);
			this.addressDisplay = this.getAddressDisplay(address);
			// console.log("set-and-show-address(): addressDisplay=", this.addressDisplay);
		},
		


		getAddressDisplay(address)
		{
			// console.log("set-and-show-address::getAddressDisplay(): address=", address);

			return addressHelpers.getAddressDisplay(address);
		},



		getSetButtonLabel()
		{
			// No address set yet. Just a generic default label.
			if (!this.address) return "Set address";

			// An address is already set. An address should always have an ID set (placeholder address record)
			// when created, so we need to see if any actual address values have been set instead.
			var addressValue = this.getAddressDisplay(this.address);
			if (addressValue) return "Edit address";

			// Set an address for the first time.
			return "Add Address";
		},




		onShowAddressInput(addressId)
		{
			// console.log("onShowAddressInput(): addressId=", addressId);

			if (addressId) this.selectedAddressId = addressId;	// Note: this is kept for subsequent modal loads.
			this.showAddressEditModal = true;
		},




		onAddressModalSaved(data)
		{
			// console.log("onAddressModalSaved(): data=", data);

			// Close the modal
			this.showAddressEditModal = false;

			// Ensure selected address ID is cleared.... [later on - why? I think it was do do with re-loading new addresses. But... I don't think that's right now]
			//this.selectedAddressId = "";
			
			// Update this component's tracking of the address and display.
			this.trackedAddress = data.Address;
			this.setAddressDisplay(this.trackedAddress);

			//NOTE: 'set' action is a generic intial value. Later we may want to include 'add' and 'change'/'update' to differentiate. No needed for now.
			this.$emit("set", { action: 'set', AddressID: data.NewAddressID, Address: data.Address });
		},


		onAddressModalCancelled()
		{
			//this.$root.redirectToPath('/manage/addresses');

			// Close the modal
			this.showAddressEditModal = false;

			// Ensure selected address ID is cleared.
			this.selectedAddressId = "";
		},


	}
}
</script>





<style lang="scss" scoped>

</style>