const routes = [

	
	//---- CRM
	{
		path: '/crm',
		name: "crm-index", 
		component: () => import("./crm/index.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "CRM" }
	},

	{
		path: '/crm/configure',
		name: "crm-configure", 
		component: () => import("./crm/configure.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "CRM > Configure" }
	},

]


export default routes;