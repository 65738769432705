const routes = [

	// Notes: Set `meta.isPublic: true` for any pages not requiring authentication. This is used by `app.checkCurrentAuthTokenStillValid()` so validation is not checked on public pages.


	//-- Publicly accessible pages
	{ 
		path: '/', 
		name: "root", 
		component: () => import("./public/index.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password)" } 
	},
	{ 
		path: '/login', 
		name: "login", 
		component: () => import("./public/login.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Login", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password)" } 
	},
	{ 
		path: '/logout', 
		name: "logout", 
		component: () => import("./public/logout.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Logout", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password)" } 
	},
	{ 
		path: '/forgot-password', 
		name: "forgot-password", 
		component: () => import("./public/forgot-password.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Forgot Password", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password)" } 
	},
	{ 
		path: '/reset-password/:token', 
		name: "reset-password", 
		component: () => import("./public/reset-password.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Reset Password", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password)" } 
	},

	{ 
		path: '/not-found', 
		name: "not-found", 
		component: () => import("./public/not-found.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Not Found" } 
	},

	{ 
		path: '/delete-account', 
		name: "delete-account", 
		component: () => import("./public/delete-account.vue"),
		meta: { layout: "public", isPublic: true, pageTitle: "Request Account Deletion" } 
	},



	//TODO: Not used at the moment. Initial idea stage.
	{ 
		path: '/get-started', 
		name: "get-started-root", 
		component: () => import("./home/get-started.vue"),
		meta: { layout: "primary", isPublic: true, pageTitle: "Get Started with Lotus", kbFile: "get-started", kbTitle: "Get Started" } 
	},



	//TODO: 9 July 2023 - These endpoints are working, but disabling for now while in private beta.
	//
	// //-- Create account
	// { 	// Primary alias for /create-account/start
	// 	path: '/create-account', 
	// 	name: "create-account-start-root", 
	// 	component: () => import("./public/create-account/start.vue"),
	// 	meta: { layout: "primary-domain-consistent", isPublic: true, pageTitle: "Create Account", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password, Signup)" } 
	// },
	// {
	// 	path: '/create-account/start', 
	// 	name: "create-account-start", 
	// 	component: () => import("./public/create-account/start.vue"),
	// 	meta: { layout: "primary-domain-consistent", isPublic: true, pageTitle: "Create Account", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password, Signup)" } 
	// },
	// { 
	// 	path: '/create-account/verify', 
	// 	name: "create-account-verify", 
	// 	component: () => import("./public/create-account/verify.vue"),
	// 	meta: { layout: "primary-domain-consistent", isPublic: true, pageTitle: "Create Account - Verify Your Email", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password, Signup)" } 
	// },
	// { 
	// 	path: '/create-account/complete', 
	// 	name: "create-account-complete", 
	// 	component: () => import("./public/create-account/complete.vue"),
	// 	meta: { layout: "primary-domain-consistent", isPublic: true, pageTitle: "Create Account - Complete!", kbFile: "public", kbTitle: "Public (Home, Login, Forgot Password, Signup)" } 
	// },




	//---- Go. Allow list redirector for the mobile app and other applications.
	{ 
		path: '/go/:id', 
		name: "Go",
		component: () => import("./go/index.vue"),
		meta: { layout: "no", isPublic: true, pageTitle: "", kbFile: "", kbTitle: "" } 
	},

]


export default routes;