const routes = [

	//---- My Profile
	{ 
		path: '/my-profile', 
		name: "my-profile",
		component: () => import("./my-profile/index.vue"),
		meta: { layout: "primary", pageTitle: "My Profile", kbFile: "my-profile", kbTitle: "My Profile" } 
	},
	{ 
		path: '/my-location', 
		name: "my-location",
		component: () => import("./my-profile/my-location.vue"),
		meta: { layout: "primary", pageTitle: "My Location", kbFile: "my-location", kbTitle: "My Location" } 
	},

]


export default routes;