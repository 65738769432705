const routes = [

	
	//---- "Road Management" (POC)
	{
		path: '/module/pavements-poc',
		name: "pavements-index", 
		component: () => import("./module.pavements-poc/index.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC)" }
	},

	{
		path: '/module/pavements-poc/import',
		name: "pavements-import", 
		component: () => import("./module.pavements-poc/import.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC) > Import" }
	},

	{
		path: '/module/pavements-poc/defects',
		name: "pavements-defects", 
		component: () => import("./module.pavements-poc/defects.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC) > Defects" }
	},

	{
		path: '/module/pavements-poc/defect/new',
		name: "pavements-defect-new", 
		component: () => import("./module.pavements-poc/defects.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC) > New Defect " }
	},

	{
		path: '/module/pavements-poc/defect/edit/:internalId',
		name: "pavements-defect-edit", 
		component: () => import("./module.pavements-poc/defects.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC) > Defect " }
	},

	// road management maps test
	{
		path: '/module/pavements-poc/map',
		name: "pavements-map", 
		component: () => import("./module.pavements-poc/map-display.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Pavements (PoC)" }
	},

]


export default routes;