const routes = [



	//---- Module: "Rostering" -----//
	{ 
		path: '/module/rostering', 
		name: "rostering-index", 
		component: () => import("./module.rostering/index.vue"),
		meta: { layout: "primary", module: "rostering", pageTitle: "Rostering" }
	},




	//-- Rostering > Kiosk (Roster Check-in)
	// { 
	// 	path: '/module/rostering/kiosk-v1', 
	// 	name: "rostering-kiosk-index", 
	// 	component: () => import("./module.rostering/kiosk/index.vue"),
	// 	meta: { layout: "rostered", module: "rostering", pageTitle: "" }
	// },
	{ 
		path: '/module/rostering/kiosk', 
		name: "rostering-kiosk-index", 
		component: () => import("./module.rostering/kiosk/index.vue"),
		meta: { layout: "none", module: "rostering", pageTitle: "" }
	},

	// The old path. This needs a redirect to '/module/rostering/kiosk'
	{ 
		path: '/module/rostering/dashboard', 
		name: "rostering-dashboard-index", 
		component: () => import("./module.rostering/kiosk/index.vue"),
		meta: { layout: "none", module: "rostering", pageTitle: "" }
	},




	//-- Rostering > Manage
	{ 
		path: '/module/rostering/admin', 
		name: "rostering-admin-page", 
		component: () => import("./module.rostering/manage/rostered-jobs.vue"),
		meta: { layout: "primary", layoutContainer: "none", module: "rostering", pageTitle: "Rostering Admin" }
	},


	{ 
		path: '/module/rostering/admin/new', 
		name: "rostering-admin-new-job", 
		component: () => import("./module.rostering/manage/edit-roster-job.vue"),
		meta: { layout: "primary", layoutContainer: "none", module: "rostering", pageTitle: "Rostering Admin > New Job" }
	},
	

	{ 
		path: '/module/rostering/admin/job/:jobId/edit', 
		name: "rostering-admin-edit-job", 
		component: () => import("./module.rostering/manage/edit-roster-job.vue"),
		meta: { layout: "primary", layoutContainer: "none", module: "rostering", pageTitle: "Rostering Admin > Edit Job" }
	},

	

	{ 
		path: '/module/rostering/admin/reports/payroll', 
		name: "rostering-admin-reports-payroll", 
		component: () => import("./module.rostering/manage/payroll-report.vue"),
		meta: { layout: "primary", layoutContainer: "none", module: "rostering", pageTitle: "Rostering Admin > Payroll Report" }
	},

]


export default routes;