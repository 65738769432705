const routes = [


	//---- Logged in "Home" page (start/landing/dashboard)
	{
		path: '/home', 
		name: "home", 
		component: () => import("./home/index.vue"),
		meta: { layout: "primary", layoutType: "default", layoutContainer: "none", isPublic: false, pageTitle: "Home", kbFile: "home", kbTitle: "" } 
	},
	{ 
		path: '/home/LotusOnly', 
		name: "home-lotus-only-view", 
		component: () => import("./home/index.vue"),
		meta: { layout: "primary", layoutType: "default", layoutContainer: "none", isPublic: false, pageTitle: "Lotus Only > Home", kbFile: "home", kbTitle: "" } 
	},






	//---- Support
	{ 
		path: '/support', 
		name: "support",
		component: () => import("./support/index.vue"),
		meta: { layout: "primary", pageTitle: "Request Support", kbFile: "support", kbTitle: "Support" } 
	},

	

	//---- "KB"
	{ 
		path: '/kb', 
		name: "kb", 
		component: () => import("./kb/index.vue"),
		meta: { layout: "primary", pageTitle: "Knowledgebase" } 
	},
	{ 
		path: '/kb/with-path',
		name: "kb-with-path", 
		component: () => import("./kb/index.vue"),
		meta: { layout: "knowledgebase", pageTitle: "Knowledgebase" } 
	},
	{ 
		path: '/kb/route/:routeName', 
		name: "kb-with-route-name", 
		component: () => import("./kb/index.vue"),
		meta: { layout: "primary", pageTitle: "Knowledgebase" } 
	},
	{ 
		path: '/kb/glossary/:term', 
		name: "kb-glossary", 
		component: () => import("./kb/index.vue"),
		meta: { layout: "primary", pageTitle: "Knowledgebase > Glossary" } 
	},



	//---- "Background Tasks"
	{ 
		path: '/background-tasks',
		name: "background-tasks", 
		component: () => import("./shared/background-tasks.vue"),
		meta: { layout: "primary", pageTitle: "Background Tasks" }
	},








]


export default routes;