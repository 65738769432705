const routes = [

	
	//---- Module: "Tessta Clients" (Tessta Client Portal)
	{ 
		path: '/module/tessta-clients', 
		name: "tessta-clients-index", 
		component: () => import("./module.tessta-clients/home/index.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta" }
	},

	{ 
		path: '/module/tessta-clients/home', 
		name: "tessta-clients-home-index", 
		component: () => import("./module.tessta-clients/home/index.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Home" },
		props:true, 
	},


	{ 
		path: '/module/tessta-clients/sites', 
		name: "tessta-clients-sites-index", 
		props:true, 
		component: () => import("./module.tessta-clients/sites/index.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Sites" }
	},
	{ 
		path: '/module/tessta-clients/sites/view/:siteId', 
		name: "tessta-clients-sites-edit",
		props:true, 
		component: () => import("./module.tessta-clients/sites/view-site.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Sites > View Site" }
	},


	{ 
		path: '/module/tessta-clients/defects', 
		name: "tessta-clients-defects-index", 
		component: () => import("./module.tessta-shared/defects/index.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Defects" },
		props:true, 
	},
	{ 
		path: '/module/tessta-clients/defects/view/:defectId', 
		name: "tessta-clients-defects-edit",
		props:true,
		component: () => import("./module.tessta-shared/defects/view-defect.vue"),
		meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Defects > View Defect" }
	},



	// { 
	// 	path: '/module/tessta-clients/assessments', 
	// 	name: "tessta-clients-assessments-index", 
	// 	component: () => import("./module.tessta-shared/defects/index.vue"),
	// 	meta: { layout: "primary2", module: "tesstaClients", pageTitle: "Tessta > Defects" }
	// },

]


export default routes;