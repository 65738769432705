const routes = [

	
	//---- "Timesheet" pages

	{ 
		path: '/home/timesheets', 
		name: "home-timesheets-view", 
		component: () => import("./home/index.vue"),
		meta: { layout: "primary", layoutType: "default", layoutContainer: "none", isPublic: true, pageTitle: "Timesheets > Home", kbFile: "home", kbTitle: "" } 
	},

	//-- "Practitioner" pages
	{ 
		path: '/module/timesheets/create', 
		name: "timesheet-create", 
		component: () => import("./module.timesheets/create-timesheet.vue"),
		meta: { layout: "primary", pageTitle: "Create Timesheet", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},

	//TODO: 20 June 2021. The original "read-only" timesheet page is now obsolete. Redirect to edit page (because it has a different layout).
	// { 
	// 	path: '/timesheet', 
	// 	name: "no-selected-timesheet", 
	// 	component: () => import("./module.timesheets/timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > View", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },
	// { 
	// 	path: '/module/timesheets/id/:id', 
	// 	name: "view-timesheet", 
	// 	// component: () => import("./module.timesheets/timesheet-page"),
	// 	component: () => import("./module.timesheets/edit-timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > View", satus: "disabled", redirectCode: "edit-timesheet", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },

	// //TODO: The following "*/ConfirmViaEmail/*" routes are not yet active. See daily timesheet email in the API for generated links.
	// { 
	// 	path: '/module/timesheets/ConfirmViaEmail/:id/:authToken', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
	// 	name: "timesheet-confirm-via-email-auth", 
	// 	component: () => import("./module.timesheets/edit-timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > Confirm", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },
	// { 
	// 	path: '/module/timesheets/ConfirmViaEmail/:id', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
	// 	name: "timesheet-confirm-via-email", 
	// 	component: () => import("./module.timesheets/edit-timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > Confirm", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },
	// { 
	// 	path: '/module/timesheets/ChangeViaEmail/:id/:authToken', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
	// 	name: "timesheet-change-via-email-auth", 
	// 	component: () => import("./module.timesheets/edit-timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > Change", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },
	// { 
	// 	path: '/module/timesheets/ChangeViaEmail/:id', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
	// 	name: "timesheet-change-via-email", 
	// 	component: () => import("./module.timesheets/edit-timesheet-page"),
	// 	meta: { layout: "practitioner", pageTitle: "Timesheets > Change", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	// },
	
	{ 
		path: '/timesheet', 
		name: "no-selected-timesheet", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > View", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},
	{ 
		path: '/module/timesheets/id/:id', 
		name: "view-timesheet", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > View", satus: "disabled", redirectCode: "edit-timesheet", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},

	//TODO: The following "*/ConfirmViaEmail/*" routes are not yet active. See daily timesheet email in the API for generated links.
	{ 
		path: '/module/timesheets/ConfirmViaEmail/:id/:authToken', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
		name: "timesheet-confirm-via-email-auth", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Confirm", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},
	{ 
		path: '/module/timesheets/ConfirmViaEmail/:id', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
		name: "timesheet-confirm-via-email", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Confirm", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},
	{ 
		path: '/module/timesheets/ChangeViaEmail/:id/:authToken', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
		name: "timesheet-change-via-email-auth", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Change", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},
	{ 
		path: '/module/timesheets/ChangeViaEmail/:id', 		//:id == timesheetId. Left as ID so standard "timesheet" route also uses it.
		name: "timesheet-change-via-email", 
		component: () => import("./home/redirect.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Change", satus: "disabled", redirectCode: "home", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},



	{
		path: '/module/timesheets/id/:id/edit', 
		name: "edit-timesheet", 
		component: () => import("./module.timesheets/edit-timesheet.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Timesheet", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},

	//NOTE: Same as for the path above. This is the original URL for the page prior to the restructure of the platform into modiles.
	//		It's still used by daily timehsheet emails generated overnight for users, so we need to keep it (and update that link)
	{
		path: '/timesheets/id/:id/edit', 
		name: "edit-timesheet-old-daily-email-link", 
		component: () => import("./module.timesheets/edit-timesheet.vue"),
		meta: { layout: "primary", layoutContainer: "none", pageTitle: "Timesheet", kbFile: "timesheet", kbTitle: "Timesheet" } 
	},



	//-- Timesheet "Manager" pages (Team Manager and Project Manager)
	{ 
		path: '/module/timesheets/manager/timesheets-report', 
		name: "timesheets-manager-report",
		component: () => import("./module.timesheets/manager/timesheets-report.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Manager > Timesheet Report" } 
	},
	{ 
		path: '/module/timesheets/manager/outstanding-timesheets', 
		name: "timesheets-manager-outstanding",
		component: () => import("./module.timesheets/manager/outstanding-timesheets.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Manager > Outstanding Timesheets" }
	},
	{ 
		path: '/module/timesheets/manager/manager-users-list', 
		name: "timesheets-manager-users-list",
		component: () => import("./module.timesheets/manager/users-list.vue"),
		meta: { layout: "primary", pageTitle: "Timesheets > Manager > Users" }
	},


	// Record time in today's timesheet
	{ 
		path: '/module/timesheets/record', 
		name: "record-timesheet", 
		component: () => import("./module.timesheets/time-recorder/index.vue"),
		meta: { layout: "primary", pageTitle: "Timesheet > Record Time", kbFile: "timesheet-record-time", kbTitle: "Timesheet > Record Time" } 
	},



	// Reports
	{ 
		path: '/module/timesheets/reports/timesheet-summary-report', 
		name: "timesheet-summary-report", 
		component: () => import("./module.timesheets/reports/timesheet-summary-report.vue"),
		meta: { layout: "primary", pageTitle: "Timesheet > Reports > Timesheet Summary Report", kbFile: "timesheet-summary-report", kbTitle: "Timesheet > Reports" } 
	},






		//---- Timesheet module: "Buckets Report" pages
		{ 
			//path: '/buckets', 
			path: '/module/timesheets/buckets-report', 
			name: "timesheet-buckets-report-entry",
			component: () => import("./module.timesheets/buckets/index.vue"),
			meta: { layout: "primary", pageTitle: "Buckets Report", kbFile: "buckets", kbTitle: "Buckets" } 
		},
		{ 	// For system admin, and later when a user can operate across accounts
			//path: '/buckets/:accountId', 
			path: '/module/timesheets/buckets-report/:accountId', 
			name: "timesheet-buckets-report-account",
			component: () => import("./module.timesheets/buckets/index.vue"),
			meta: { layout: "primary", pageTitle: "Buckets Report", kbFile: "buckets", kbTitle: "Buckets" } 
		},

		{ 
			//path: '/buckets/bucket/:bucketId/summary', 
			path: '/module/timesheets/buckets-report/bucket/:bucketId/summary', 
			name: "timesheet-buckets-report-bucket-summary",
			component: () => import("./module.timesheets/buckets/bucket-summary.vue"),
			meta: { layout: "primary", pageTitle: "Buckets Report > Bucket Summary", kbFile: "buckets", kbTitle: "Buckets" } 
		},
		{ 
			//path: '/buckets/bucket/:bucketId/detail', 
			path: '/module/timesheets/buckets-report/bucket/:bucketId/detail', 
			name: "timesheet-buckets-report-bucket-detail",
			component: () => import("./module.timesheets/buckets/bucket-detail.vue"),
			meta: { layout: "primary", layoutType: "manage", pageTitle: "Buckets Report > Bucket Detail", kbFile: "buckets", kbTitle: "Buckets" } 
		},

		{ 
			//path: '/buckets/bucket/:bucketId/breakdown', 
			path: '/module/timesheets/buckets-report/bucket/:bucketId/breakdown', 
			name: "timesheet-buckets-report-bucket-breakdown",
			component: () => import("./module.timesheets/buckets/bucket-breakdown.vue"),
			meta: { layout: "primary", pageTitle: "Buckets Report > Bucket Breakdown", kbFile: "buckets", kbTitle: "Buckets" } 
		},

		

		//---- "Keywords" pages
		{ 
			path: '/module/timesheets/keywords/new-detected', 
			name: "timesheets-new-detected-keywords", 
			component: () => import("./module.timesheets/keywords/new-detected.vue"),
			meta: { layout: "primary", section: "keywords", pageTitle: "Keywords > New Detected" }
		},


]


export default routes;