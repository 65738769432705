<template>
    <span class="c-lotus-only" title="Viewable only by Lotus"><i class="fas fa-eye-slash"></i></span>
</template>





<script>
export default {

}
</script>





<style lang="scss" scoped>
    .c-lotus-only
    {
		cursor: help;
    }
</style>