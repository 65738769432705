<template>
	<div class="c-layout-container">
		<div class="card" v-if="type == 'card'">	
			<slot></slot>
		</div>
		<div v-if="type == 'none'">	
			<slot></slot>
		</div>
	</div>
</template>

<script>
//TODO: Import the styles of <el-card> so we can remove that framework.

export default {
	props: {
		type: {
			type: String,
			default: "card"
		}
	}
}
</script>

<style lang="scss" scoped>
	// Copied form the original <el-card class="box-card">
	.c-layout-container .card
	{
		box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);;
		padding: 20px;
		border: 1px solid #e4e7ed;
		background-color: #ffffff;
		border-radius: 4px;
		color: #000000;
		overflow: inherit;
		transition: 0.3s;
	}
</style>