const routes = [

	// Note: 2025-01-02, jsnelders: All routes used to be directly in this array.
	//	They have now been split out into files for each module or logical grouping of
	//	related functionality for each management. Folllow the pattern when adding
	//	new modules or areas.


]



import publicRoutes from "./routes.public.js";
routes.push(...publicRoutes);

import otherRoutes from "./routes.other.js";
routes.push(...otherRoutes);

import crmRoutes from "./routes.crm.js";
routes.push(...crmRoutes);

import timesheetsRoutes from "./routes.timesheets.js";
routes.push(...timesheetsRoutes);

import myProfileRoutes from "./routes.my-profile.js";
routes.push(...myProfileRoutes);

import accountAdminRoutes from "./routes.account-admin.js";
routes.push(...accountAdminRoutes);

import manageRoutes from "./routes.manage.js";
routes.push(...manageRoutes);

import quotesRoutes from "./routes.quotes.js";
routes.push(...quotesRoutes);

import pavementsPocRoutes from "./routes.pavements-poc.js";
routes.push(...pavementsPocRoutes);

import systemAdminRoutes from "./routes.system-admin.js";
routes.push(...systemAdminRoutes);

import developersRoutes from "./routes.developers.js";
routes.push(...developersRoutes);

import testRoutes from "./routes.test.js";
routes.push(...testRoutes);

import mobileAppRoutes from "./routes.mobile-app.js";
routes.push(...mobileAppRoutes);

import tesstaRoutes from "./routes.tessta.js";
routes.push(...tesstaRoutes);

import tesstaClientsRoutes from "./routes.tessta-clients.js";
routes.push(...tesstaClientsRoutes);

import rosteringRoutes from "./routes.rostering.js";
routes.push(...rosteringRoutes);



export default routes;