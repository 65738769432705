const routes = [

	
	//---- "System Admin" / "Lotus Admin" pages
	{ 
		path: '/system-admin/access-definitions', 
		component: () => import("./system-admin/access-definitions.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Feature Access Definitions" }
	},

	{ 
		path: '/system-admin/timesheet-generation', 
		component: () => import("./system-admin/timesheet-generation.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Timesheet Generation" }
	},

	{ 
		path: '/system-admin/accounts', 
		name: "system-admin-accounts",
		component: () => import("./system-admin/accounts.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Accounts" }
	},
	{ 
		path: '/system-admin/accounts/new', 
		name: "ystem-admin-accounts-edit",
		component: () => import("./system-admin/account.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account" }
	},

	// { 
	// 	path: '/system-admin/account/:id/edit',
	// 	name: "account-admin-home", 
	// 	component: () => import("./account-admin/index.vue"),
	// 	//meta: { layout: "account-admin", pageTitle: "Account Admin", kbFile: "account-admin", kbTitle: "Account Admin" }
	// 	meta: { layout: "primary", layoutType: "account-admin", layoutContainer: "none", pageTitle: "Account Admin > Account details", kbFile: "account-admin", kbTitle: "Account Admin" }
	// },
	{ 
		path: '/system-admin/account/:id/edit', 
		name: "system-admin-accounts-edit",
		component: () => import("./system-admin/account.vue"),
		meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Edit" }
	},


	//TODO: Obsolete
	//NOTE: import() even in commented code will still try to be resolved during build. WTF?!

	// { 
	// 	path: '/system-admin/account/:id/rates', 
	// 	name: "system-admin-rates",
	// 	component: () => import("./system-admin/account/rates.vue"),
	// 	meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Rates" }
	// },
	// { 
	// 	path: '/system-admin/account/:id/clients',
	// 	component: () => import("./system-admin/account/clients.vue"),
	// 	meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Clients" }
	// },
	// { 
	// 	path: '/system-admin/account/:id/buckets', 
	// 	component: () => import("./system-admin/account/buckets.vue"),
	// 	meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Buckets" }
	// },
	// { 
	// 	path: '/system-admin/account/:id/buckets/new', 
	// 	component: () => import("./system-admin/account/bucket.vue"),
	// 	meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > New Bucket" }
	// },
	// { 
	// 	path: '/system-admin/account/:id/buckets/:bucketId/edit', 
	// 	component: () => import("./system-admin/account/bucket.vue"),
	// 	meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Edit Bucket" }
	// },
	// { 
	// 	path: '/system-admin/account/:id/buckets/:bucketId/tasks', 
	// 	component: () => import("./system-admin/account/bucket-tasks.vue"),
	// 	meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Bucket Tasks" }
	// },
	// { 
	// 	name: "SystemAdminAccountOrganisationRoles",
	// 	path: '/system-admin/account/:id/organisation-roles', 
	// 	component: () => import("./system-admin/account/organisation-roles.vue"),
	// 	meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Organisation Roles" }
	// },
	// { 
	// 	name: "SystemAdminBucketRates",
	// 	path: '/system-admin/account/:id/buckets/:bucketId/rates', 
	// 	component: () => import("./system-admin/account/rates.vue"),
	// 	meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Bucket Rates" }
	// },
	// { 
	// 	path: '/system-admin/account/:id/bucket-hierarchies', 
	// 	component: () => import("./system-admin/account/bucket-hierarchies.vue"),
	// 	meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Account > Bucket Hierarchies" }
	// },
	// { 
	// 	path: '/system-admin/account/:id/keywords', 
	// 	component: () => import("./system-admin/account/keywords.vue"),
	// 	meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Keywords" }
	// },
	// { 
	// 	path: '/system-admin/account/:id/users', 
	// 	component: () => import("./system-admin/account/users.vue"),
	// 	meta: { layout: "primary", section: "system-admin", pageTitle: "Lotus Admin > Users" }
	// },


	{ 
		path: '/system-admin/review-timesheets', 	//NOTE: This is the primary version for the following 4, which should really be considered obsolete and consolidated into this one (via filters)
		component: () => import("./system-admin/timesheets/list-timesheets.vue"),
		name: "SystemAdminReviewTimesheets",
		meta: { layout: "primary", pageTitle: "System Admin > Review Timesheets" }
	},
	{
		path: '/system-admin/timesheets/id/:id/activity', 
		component: () => import("./system-admin/timesheets/timesheet-activity.vue"),
		name: "SystemAdminTimesheetsTimesheetActivity", 
		meta: { layout: "primary", pageTitle: "System Admin > Timesheet Activity" }
	},



	{ 
		path: '/system-admin/reports', 
		name: "system-admin-reports",
		component: () => import("./system-admin/reports/index.vue"),
		meta: { layout: "primary", pageTitle: "System Admin > Reports" }
	},
	{ 
		path: '/system-admin/reports/database/:id', 
		component: () => import("./system-admin/reports/database.vue"),
		meta: { layout: "primary", pageTitle: "System Admin > Reports > Database Reports" }
	},
	{ 
		path: '/system-admin/reports/:id', 
		name: "system-admin-reports-by-id",
		component: () => import("./system-admin/reports/report.vue"),
		meta: { layout: "primary", pageTitle: "System Admin > Reports" }
	},

]


export default routes;